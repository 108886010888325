import { Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useState } from 'react'

import useFeedNavigation from '../../hooks/useFeedNavigation'
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen'
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut'
import IconDownArrow from '../../icons/IconDownArrow.svg'
import IconFilters from '../../icons/IconFilters.svg'
import IconMixLogo from '../../icons/IconMixLogo.svg'
import { FeedFiltersModal } from '../../pages/node/components/FeedFilters'
import { UrlActions } from '../../pages/url/components/UrlActions'
import { UrlShareActions } from '../../pages/url/components/UrlShareActions'
import urlApi from '../../redux/api/urlApi'
import { selectCurrentFeed } from '../../redux/slices/feedSlice'
import { useAppSelector } from '../../redux/store/store'

interface ToolbarMixButtonProps {
  isNextVisible: boolean
  onClick: () => void
}

const ToolbarMixButton: React.FC<ToolbarMixButtonProps> = ({ isNextVisible, onClick }) => (
  <button
    onClick={onClick}
    className={`btn group flex cursor-pointer items-center justify-center gap-1.5 overflow-hidden rounded-full transition-all duration-200 ease-in-out hover:scale-105 light:bg-neutral-800 dark:bg-accent-dark ${
      isNextVisible ? 'px-3 py-2' : 'p-3'
    }`}
  >
    <IconMixLogo className="size-9" />
    <Transition
      show={isNextVisible}
      as={Fragment}
      enter="transition-all duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all duration-300"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-0"
    >
      {ref => (
        <div className="flex items-center justify-center gap-1.5" ref={ref}>
          <span className="text-primary opacity-50 transition-opacity group-hover:opacity-100 light:text-white light:opacity-70">
            Next
          </span>
          <IconDownArrow className="size-4 text-primary opacity-50 transition-opacity group-hover:opacity-100 light:text-white light:opacity-70" />
        </div>
      )}
    </Transition>
  </button>
)

const ToolbarActionsLoading = () => (
  <div className="flex animate-pulse items-center justify-between space-x-6">
    <div className="w-10" />
    <div className="flex h-12">
      <div className="relative flex items-center rounded-full bg-contrast/10 px-2 py-1">
        <div className="z-10 flex size-11 p-3 opacity-40"></div>
        <ToolbarMixButton isNextVisible={false} onClick={() => {}} />
        <div className="z-10 flex size-11 p-3 opacity-40"></div>
      </div>
    </div>
    <div className="w-10" />
  </div>
)

export const ToolbarActions = () => {
  const [isFeedFiltersModalVisible, setIsFeedFiltersModalVisible] = useState(false)
  const isSmallScreen = useIsSmallScreen()
  const { isFiltersEnabled, isUrlActionsEnabled, isGridActionsEnabled, isSharingEnabled } = useAppSelector(
    state => state.header
  )
  const feed = useAppSelector(selectCurrentFeed)

  const { currentFeedItemKey } = useAppSelector(state => state.feed)
  const { data, isLoading } = urlApi.useGetUrlDetailsQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    { urlId: currentFeedItemKey!, cookie: null },
    { skip: !currentFeedItemKey }
  )
  const currentUrl = data?.url[0]

  const { handlePreviousNavigation, handleNextNavigation } = useFeedNavigation()

  //#region Keyboard Navigation
  const handleArrowDown = useCallback(() => {
    handleNextNavigation()?.catch(() => {})
  }, [handleNextNavigation])

  useKeyboardShortcut({
    codes: {
      ArrowUp: handlePreviousNavigation,
      ArrowDown: handleArrowDown,
    },
  })
  //#endregion

  if (isSmallScreen) return null
  if (isLoading && isUrlActionsEnabled) return <ToolbarActionsLoading />
  if (isGridActionsEnabled && !feed)
    return (
      <div className="flex h-12 animate-pulse items-center">
        <ToolbarMixButton isNextVisible={false} onClick={() => {}} />
      </div>
    )
  return (
    <>
      <div className="flex h-12 items-center justify-between space-x-6">
        <div className="size-10">
          {isFiltersEnabled && (
            <button
              className="z-50 shrink-0 text-primary opacity-50 transition-all duration-150 hover:rounded-full hover:bg-contrast/5 hover:text-primary hover:opacity-100"
              onClick={() => setIsFeedFiltersModalVisible(true)}
            >
              <IconFilters className="size-10 p-2" />
            </button>
          )}
        </div>
        {isUrlActionsEnabled ? (
          <UrlActions urlId={currentUrl?.url_id}>
            <ToolbarMixButton isNextVisible={true} onClick={handleNextNavigation} />
          </UrlActions>
        ) : null}
        {isGridActionsEnabled && feed ? (
          <ToolbarMixButton isNextVisible={false} onClick={handleNextNavigation} />
        ) : null}
        <div className="size-10">{isSharingEnabled && currentUrl && <UrlShareActions url={currentUrl} />}</div>
      </div>
      {isFiltersEnabled ? (
        <FeedFiltersModal onClose={() => setIsFeedFiltersModalVisible(false)} isVisible={isFeedFiltersModalVisible} />
      ) : null}
    </>
  )
}
