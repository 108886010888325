import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { setModalStateBasedOnPath } from '../redux/slices/modals/addUrlModalSlice'
import { useAppDispatch } from '../redux/store/store'

export const RouterEvents = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      dispatch(setModalStateBasedOnPath(url))
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, dispatch])
  return <></>
}
