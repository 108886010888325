import { stringify } from 'qs'

import { useGlobalContext } from '../context/GlobalContexts'
import { isServer } from '../lib/runtime-environment'
import runtimeConfig from '../lib/RuntimeConfig'

interface BrowserInfo {
  ios: boolean
  android: boolean
  isTablet: boolean
}

/**
 * Returns a query string for a deeplink that can be appended to a Branch link.
 *
 * @param browserInfo - The browser information from useGlobalContext().
 * @param deeplinkUrl - The deeplink URL. If not provided, the current window location will be used.
 * @returns The query string for the deeplink.
 */
export const getDeeplinkQueryString = (browserInfo: BrowserInfo, deeplinkUrl: string | null | undefined): string => {
  const { ios, isTablet } = browserInfo

  // iPad loads the website with desktop mode by default.
  // This prevents branch from opening the AppStore link in iPad.
  // We are setting fallbackURL in iPad to force redirect to the AppStore
  const fallbackURL = isTablet && ios ? 'https://apps.apple.com/us/app/mix-expand-your-mind/id1092817691' : undefined

  const deeplinkUrlOrCurrentLocation = deeplinkUrl ?? (typeof window !== 'undefined' ? window.location.href : '')

  const urlWithoutProtocol = (url: string) => url.replace(/^(https?:|)\/\//gi, '')
  const deeplinkData = {
    // Delivered as query parameter when handling the universal links.
    deeplink_url: deeplinkUrlOrCurrentLocation,
    // Fallbacks to mix://$deeplink_path when universal links do not work.
    // Docs: https://docs.branch.io/pages/links/integrate/#deep-linking
    $deeplink_path: urlWithoutProtocol(deeplinkUrlOrCurrentLocation),
    $fallback_url: fallbackURL,
  }
  return stringify(deeplinkData, { addQueryPrefix: true })
}

export const useDeeplinkInfo = (
  deeplinkUrl?: string | null
): { deeplinkUrl: string; platform: 'android' | 'ios' } | undefined => {
  const { browserInfo } = useGlobalContext()
  const { android, ios } = browserInfo
  const platform = android ? 'android' : ios ? 'ios' : undefined
  if (!platform) return
  if (!deeplinkUrl && isServer()) return

  const { deeplinks } = runtimeConfig().publicRuntimeConfig
  const deeplink = deeplinks[platform]
  const queryString = getDeeplinkQueryString(browserInfo, deeplinkUrl)
  return { deeplinkUrl: `${deeplink}${queryString}`, platform }
}
