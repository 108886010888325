import { useRouter } from 'next/router'
import qs from 'qs'
import { FormEventHandler, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import toast from 'react-hot-toast'

import { useTrackClick } from '../../hooks/analytics/useTrackClick'
import logger from '../../lib/logger'
import { isServer } from '../../lib/runtime-environment'
import UrlHelper from '../../lib/UrlHelper'

type AuthLinkName = 'facebookAuth' | 'twitterAuth' | 'googleAuth' | 'appleAuth'

export const AuthLink = ({
  name,
  children,
  returnUrl,
  buttonClass,
  onClick,
}: {
  name: string
  children: (isSubmitting: boolean) => ReactNode
  returnUrl?: string
  buttonClass?: string
  onClick?: () => void
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formRef = useRef<HTMLFormElement | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const trackClick = useTrackClick()
  const authLinkName = `${name.toLowerCase()}Auth` as AuthLinkName
  const { asPath } = useRouter()

  const currentPath = useMemo(() => {
    if (isServer()) return asPath
    return window.location.href.replace(window.location.origin, '')
  }, [asPath])

  const href = useMemo(() => {
    const returnPath = `${UrlHelper.asRelativePath(returnUrl) ?? currentPath}`
    return `/auth/${name.toLowerCase()}${qs.stringify({ r: returnPath }, { addQueryPrefix: true })}`
  }, [currentPath, name, returnUrl])

  const submitWithToken = useCallback(
    async (recaptchaToken: string) => {
      if (!formRef.current) {
        setIsSubmitting(false)
        return
      }

      try {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'recaptchaToken'
        input.value = recaptchaToken
        formRef.current.appendChild(input)
        formRef.current.submit()
      } catch (error) {
        logger.error('Error on reCAPTCHA submit', error)
        toast.error('Sorry, something went wrong. Please try again.')
        setIsSubmitting(false)
      }
    },
    [formRef]
  )

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async event => {
      event.preventDefault()
      setIsSubmitting(true)
      onClick?.()

      try {
        if (!executeRecaptcha) return logger.error('reCAPTCHA not available')
        const token = await executeRecaptcha('login')
        await submitWithToken(token)
      } catch (error) {
        setIsSubmitting(false)
        logger.error('Error on reCAPTCHA Enterprise.', error)
        toast.error('Unable to verify. Please try again.')
      }
    },
    [executeRecaptcha, submitWithToken, onClick]
  )

  return (
    <form ref={formRef} action={href} method="post" onSubmit={handleFormSubmit}>
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={() => trackClick(authLinkName)}
        className={`btn btn-square flex w-full cursor-pointer items-center space-x-4 p-4 focus:outline-none ${buttonClass}`}
      >
        {children(isSubmitting)}
      </button>
    </form>
  )
}
