import { useCallback } from 'react'

import { useSegmentTrack } from './useSegmentAnalytics'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum AnalyticsButton {
  // Navigation
  home,
  search,
  add,
  contactSupport,
  logout,
  userProfileMenu,
  dismiss,
  login,
  signup,
  // Feed Filters
  feedFilter,
  switcherSelection,
  // Url
  urlItem,
  urlLike,
  urlRemoveLike,
  urlDislike,
  urlRemoveDislike,
  // urlInfo,
  urlSave,
  urlRemoveSaved,
  urlOpenExternal,
  urlShare,
  // urlShareExternal,
  urlUpload,
  // User
  userFollow,
  userUnfollow,
  userSettings,
  // userMoreActions,
  userItem,
  // userShareExternal,

  // Node
  nodeItem,
  nodeFollow,
  nodeUnfollow,
  // nodeShare,

  // Login
  facebookAuth,
  twitterAuth,
  appleAuth,
  googleAuth,
  termsOfServices,
  privacyPolicy,

  // Report
  report,

  // Onboarding
  profilePictureUpload,
  topicsSubmit,
  editUser,
  saveUser,
  enableNSFW,
}

export const useTrackClick = () => {
  const track = useSegmentTrack()

  return useCallback(
    (button: keyof typeof AnalyticsButton, properties?: object) => track('click', { ...properties, button }),
    [track]
  )
}
