import { useCurrentUserContext } from '../../context/CurrentUserContext'
import { IntoUrl } from '../../models/IntoUrl'
import Modal from '../Modal'
import ExternalShareButtons from './ExternalShareButtons'
import { InternalShareUsers } from './InternalShareUsers'

interface ShareModalProps {
  url: IntoUrl
  isVisible: boolean
  onClose: () => void
}
export const UrlShareModal = ({ url, isVisible, onClose }: ShareModalProps) => {
  const { isLoggedIn } = useCurrentUserContext()
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className="relative z-10 flex max-h-screen w-screen flex-col space-y-6 rounded-lg bg-secondary py-4 scrollbar-hide selection:bg-accent/20 light:bg-menu sm:w-fit sm:max-w-2xl">
        <div className="px-4 text-center font-semibold">Share via</div>
        <ExternalShareButtons url={url} onClose={onClose} />
        {isLoggedIn && <InternalShareUsers key={url.url_id} urlID={url.url_id} onClose={onClose} />}
      </div>
    </Modal>
  )
}
