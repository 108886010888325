import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'
import { v4 as uuidv4 } from 'uuid'

import { requestInterceptor } from '../lib/commands/overrideCommandContextInterceptor'
import { setCanaryInterceptor } from '../lib/isCanary'

const traceIdInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const traceId = uuidv4()
  config.headers = config.headers || ({} as AxiosRequestHeaders)
  if (config.headers) {
    config.headers['x-datadog-trace-id'] = traceId
    config.headers['x-datadog-parent-id'] = 0
  }

  return config
}

axios.interceptors.request.use(setCanaryInterceptor)
axios.interceptors.request.use(requestInterceptor, undefined, { synchronous: true })
axios.interceptors.request.use(traceIdInterceptor)

export default axios
