import '../services/MixAxios'
import '../styles/globals.css'

import { getCookie } from 'cookies-next'
import { enableMapSet } from 'immer'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import App from 'next/app'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Toaster } from 'react-hot-toast'
import { persistStore } from 'redux-persist'

import { AddUrlModal } from '../components/AddUrlModal'
import { AppProviders } from '../components/AppProviders'
import BusinessEventsModal from '../components/BusinessEventsModal'
import Layout from '../components/layouts/Layout'
import { LoginModal } from '../components/login/LoginModal'
import { MetaTags } from '../components/MetaTags'
import { RootSWRegistry } from '../components/RootSWRegistry'
import { RouterEvents } from '../components/Router'
import { CommandContextInterceptor } from '../context/CommandContextInterceptor'
import { ObserveSessionID } from '../context/ObserveSessionID'
import { ReferrerContextObserver } from '../context/ReferrerContextObserver'
import useRootServiceWorker from '../hooks/useServiceWorker'
import { BranchSDK } from '../lib/analytics/BranchSDK'
import { GoogleAnalytics } from '../lib/analytics/GoogleAnalytics'
import { BrowserInfo, getBrowserInfo } from '../lib/browser'
import runtimeConfig from '../lib/RuntimeConfig'
import { IntoNode } from '../models/IntoNode'
import { IntoUrl } from '../models/IntoUrl'
import { IntoUser } from '../models/IntoUser'
import { setReqIP } from '../redux/slices/appSlice'
import { AppDispatch, AppStore, wrapper } from '../redux/store/store'
import utils from '../server/lib/utils'
import { MessageServiceProvider } from '../tracking/context/MessageServiceContext'

// App level initialization
// Enable map & set support for immer.js https://immerjs.github.io/immer/map-set/
enableMapSet()
type InitialProps = AppInitialProps & { isLoggedIn: boolean; browserInfo: BrowserInfo }

type MixAppProps = AppProps<{ pageName?: string; nodeDetails?: IntoNode; user?: IntoUser; url?: IntoUrl } | undefined> &
  InitialProps

const MixApp = ({ Component, ...rest }: MixAppProps) => {
  useRootServiceWorker()
  const { store, props }: { store: AppStore; props: InitialProps & MixAppProps } = wrapper.useWrappedStore(rest)
  const { pageProps, isLoggedIn, browserInfo } = props
  persistStore(store)

  return (
    <AppProviders store={store} browserInfo={browserInfo} isLoggedIn={isLoggedIn} pageName={pageProps?.pageName}>
      <CommandContextInterceptor />
      <ObserveSessionID />
      <RouterEvents />
      <BranchSDK />
      <GoogleAnalytics />
      <ReferrerContextObserver />
      <ProgressBar color="#FF8226" height="2px" options={{ showSpinner: false, trickle: false }} />
      <Layout>
        <>
          <MetaTags />
          <GoogleReCaptchaProvider
            useEnterprise={true}
            reCaptchaKey={runtimeConfig().publicRuntimeConfig.auth.recaptchaEnterpriseKey}
          >
            <MessageServiceProvider>
              <RootSWRegistry />
              <Component {...pageProps} />
              {/* Modal Root*/}
              <LoginModal />
              <AddUrlModal />
              <BusinessEventsModal />
            </MessageServiceProvider>
          </GoogleReCaptchaProvider>
        </>
      </Layout>
      <Toaster />
    </AppProviders>
  )
}

MixApp.getInitialProps = wrapper.getInitialAppProps(store => async (appContext: AppContext): Promise<InitialProps> => {
  const { ctx } = appContext
  const { req } = ctx

  const dispatch = store.dispatch as AppDispatch
  if (req) {
    const reqIP = utils.ipFromReq(req)
    if (reqIP) dispatch(setReqIP(reqIP))
  }
  const browserInfo = getBrowserInfo(req?.headers['user-agent'] ?? '')
  if (getCookie('is_ipad', { req }) === 'true') {
    browserInfo.isDesktop = false
    browserInfo.isTablet = true
  }
  const isLoggedIn =
    getCookie(runtimeConfig().publicRuntimeConfig.mixIdentifiedCookieName, {
      req,
    }) === 'true'
  const { pageProps } = await App.getInitialProps(appContext)
  return { ...pageProps, browserInfo, isLoggedIn }
})

export default MixApp
