import { Dialog, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import React, { ForwardedRef, Fragment, forwardRef, useCallback, useRef, useState } from 'react'

import { useGlobalContext } from '../context/GlobalContexts'
import { useTrackClick } from '../hooks/analytics/useTrackClick'

export interface ChildModalProps {
  isVisible: boolean
  onClose: (value: boolean) => void
  returnUrl?: string
  modalType?: string
}

type ModalProps = ChildModalProps & {
  children: JSX.Element
}

export const useModalActions = () => {
  const [isVisible, setVisibility] = useState(false)
  const toggleVisibility = useCallback(() => {
    setVisibility(isVisible => !isVisible)
  }, [setVisibility])

  return { isVisible, toggleVisibility }
}

const Modal = ({ isVisible, children, onClose, returnUrl, modalType }: ModalProps, ref: ForwardedRef<HTMLElement>) => {
  const { isModalOpen, setIsModalOpen } = useGlobalContext()
  const trackClick = useTrackClick()
  const router = useRouter()

  const ModalOpenEvent = new CustomEvent('modalOpen')
  const ModalClosedEvent = new CustomEvent('modalClose')

  const panelRef = useRef(null)
  const modalRef = React.useCallback(
    (node: HTMLElement | null) => {
      const modalList = document?.querySelectorAll('[data-headlessui-portal]') ?? []

      if (node && !isModalOpen) {
        document.dispatchEvent(ModalOpenEvent)
        setIsModalOpen(true)
      } else if (!node && modalList?.length === 1) {
        document.dispatchEvent(ModalClosedEvent)
        setIsModalOpen(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isModalOpen, setIsModalOpen]
  )

  return (
    <Transition show={isVisible} as={Fragment} ref={ref}>
      <Dialog
        ref={modalRef}
        as="div"
        open={isVisible}
        onClose={value => {
          onClose(value)
          trackClick('dismiss', { modalType, buttonType: 'backdrop' })
          if (returnUrl) void router.push(returnUrl, undefined, { shallow: true }).catch(() => {})
        }}
        className="fixed left-0 top-0 z-50 grid h-screen w-screen place-items-center overflow-y-auto"
        initialFocus={panelRef}
      >
        <Transition.Child
          className="absolute left-0 top-0 size-full bg-black/70 backdrop-blur-sm"
          aria-hidden="true"
          enter="ease-out duration-500"
          enterFrom="opacity-0 hidden"
          enterTo="opacity-100 block"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        ></Transition.Child>
        <Transition.Child
          ref={panelRef}
          tabIndex="0"
          as={Dialog.Panel}
          className="flex flex-col items-center overflow-y-auto outline-none"
          enter="ease-out duration-300"
          enterFrom="opacity-0 lg:scale-95"
          enterTo="opacity-100 lg:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 lg:scale-100"
          leaveTo="opacity-0 lg:scale-95"
        >
          {children}
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

const ModalWrapped = forwardRef(Modal)
export default ModalWrapped
