import Link from 'next/link'
import { useRouter } from 'next/router'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'

import { useTrackClick } from '../../hooks/analytics/useTrackClick'
import IconApple from '../../icons/IconApple.svg'
import IconFacebook from '../../icons/IconAuthFacebook.svg'
import IconTwitter from '../../icons/IconAuthTwitter.svg'
import IconGoogle from '../../icons/IconGoogle.svg'
import IconPlus from '../../icons/IconPlus.svg'
import IconSpinner from '../../icons/IconSpinner.svg'
import { setIsLoginModalOpen } from '../../redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store/store'
import Modal from '../Modal'
import { AuthLink } from './AuthLink'

interface LoginModalCopy {
  title: string
  subtitle: string
}

const CLEAR_ERROR_TIME = 5000

// Variations of copy on the login modal based on the way we show them
const loginCopy: Record<'default' | 'add' | 'login' | 'captcha' | 'personalize', LoginModalCopy> = {
  // This is the default copy.
  default: {
    title: 'Join Mix',
    subtitle: 'Your AI powered personal feed of images, videos, gifs and more.',
  },
  // Shown when an unauthenticated user visits the /add page
  add: {
    title: 'Welcome to Mix',
    subtitle: 'Please sign up or login to submit the link.',
  },
  // Shown when a user clicks on a "Login" button somewhere (not the Join or Sign up).
  login: {
    title: 'Welcome to Mix',
    subtitle: 'Please login to continue.',
  },
  // Shown when we present captcha box during the sign-up.
  captcha: {
    title: 'Before we continue',
    subtitle: 'Please verify you are a human',
  },
  // Shown when we automatically trigger the login modal on scroll
  personalize: {
    title: 'Join Mix to personalize your experience',
    subtitle: 'Your Al-powered personal feed of interesting things for you.',
  },
}

const LoginModalInner = (_: unknown, ref: ForwardedRef<HTMLElement>) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const trackClick = useTrackClick()
  const [hasVerificationError, setHasVerificationError] = useState(
    router.query.error === 'verificationError' || router.query.error === 'missingToken'
  )

  useEffect(() => {
    setTimeout(() => {
      setHasVerificationError(false)
    }, CLEAR_ERROR_TIME)
  }, [hasVerificationError])

  const { isLoginModalOpen, returnPathOnClose, returnUrl, copyVariant } = useAppSelector(state => state.loginModal)
  const onClose = () => {
    dispatch(setIsLoginModalOpen(false))
    if (returnPathOnClose) router.replace('/', returnPathOnClose, { shallow: true }).catch(() => {})
  }
  const copy = loginCopy[copyVariant]

  return (
    <Modal isVisible={isLoginModalOpen} onClose={onClose} ref={ref}>
      <div className="relative w-full rounded-lg bg-menu p-10 pt-12 text-menu selection:bg-accent/50 sm:w-[30rem]">
        <button className="absolute right-6 top-6" tabIndex={-1} onClick={onClose}>
          <IconPlus className="rotate-45 text-[#999999]" />
        </button>
        <div className="space-y-8">
          <div className="space-y-6">
            <div className="text-3xl font-bold">{copy.title}</div>
            <div className="text-xl">{copy.subtitle}</div>
          </div>
          {hasVerificationError && (
            <div className="text-lg text-red-500">
              {`We couldn't verify your request. Please try again or use a different login method.`}
            </div>
          )}
          <div className="w-full flex-col space-y-2">
            <AuthLink
              name="Facebook"
              buttonClass="text-sm hover:border-accent border-2 rounded-lg"
              {...{ returnUrl }}
              onClick={() => setHasVerificationError(false)}
            >
              {isSubmitting => (
                <>
                  <span className="w-6">
                    <IconFacebook />
                  </span>
                  <span className="w-full text-left">Facebook</span>
                  {isSubmitting && <IconSpinner className="h-6" />}
                </>
              )}
            </AuthLink>
            <AuthLink
              name="Twitter"
              buttonClass="text-sm hover:border-accent border-2 rounded-lg"
              onClick={() => setHasVerificationError(false)}
              {...{ returnUrl }}
            >
              {isSubmitting => (
                <>
                  <span className="w-6">
                    <IconTwitter />
                  </span>
                  <span className="w-full text-left">Twitter</span>
                  {isSubmitting && <IconSpinner className="h-6" />}
                </>
              )}
            </AuthLink>
            <AuthLink
              name="Google"
              buttonClass="text-sm hover:border-accent border-2 rounded-lg"
              onClick={() => setHasVerificationError(false)}
              {...{ returnUrl }}
            >
              {isSubmitting => (
                <>
                  <span className="w-6">
                    <IconGoogle />
                  </span>
                  <span className="w-full text-left">Google</span>
                  {isSubmitting && <IconSpinner className="h-6" />}
                </>
              )}
            </AuthLink>
            <AuthLink
              name="Apple"
              buttonClass="text-sm hover:border-accent border-2 rounded-lg"
              onClick={() => setHasVerificationError(false)}
              {...{ returnUrl }}
            >
              {isSubmitting => (
                <>
                  <span className="w-6">
                    <IconApple />
                  </span>
                  <span className="w-full text-left">Apple</span>
                  {isSubmitting && <IconSpinner className="h-6" />}
                </>
              )}
            </AuthLink>
          </div>
          <div className="text-sm leading-tight text-black/60">
            By continuing, you are setting up a Mix account and agree to our{' '}
            <Link href="/tos" onClick={() => trackClick('termsOfServices')} className="text-accent">
              Terms of Services
            </Link>{' '}
            and{' '}
            <Link href="/privacy" onClick={() => trackClick('privacyPolicy')} className="text-accent">
              Privacy Policy.
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const LoginModal = forwardRef(LoginModalInner)
