import axios from 'axios'
import debounce from 'lodash.debounce'
import Long from 'long'
import { ApiError } from 'next/dist/server/api-utils'
import { useRouter } from 'next/router'
import React, { ChangeEvent, FormEvent, useCallback, useMemo, useRef } from 'react'
import toast from 'react-hot-toast'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

import { useCommandContext } from '../context/CommandContext'
import { useCurrentUserContext } from '../context/CurrentUserContext'
import { useTrackClick } from '../hooks/analytics/useTrackClick'
import { useRequest } from '../hooks/useRequest'
import { commands, globals } from '../lib/messages/protobuf'
import { convertStringToURL } from '../lib/StringHelper'
import UrlHelper from '../lib/UrlHelper'
import { errorToHelpfulMessage } from '../models/APIErrorResponse'
import { ExtractMetaResponse } from '../models/ExtractResponse'
import { setIsAddUrlModalOpen, setUrl } from '../redux/slices/modals/addUrlModalSlice'
import { useAppDispatch, useAppSelector } from '../redux/store/store'
import IntoAPI from '../services/IntoAPI'
import MixMix from '../services/MixMix'
import Modal from './Modal'
import { UrlExtractMeta, UrlExtractMetaPlaceholder } from './UrlExtractMeta'

import RateUrl = commands.RateUrl

import TrackContentView = commands.TrackContentView
import ContentViewType = globals.ContentViewType
import RateUrlType = globals.RateUrlType
import PageContext = globals.PageContext
import PageType = globals.PageContext.PageType

export const USER_ENTRY_DEBOUNCE = 300

export const useUrlSubmission = (label?: string) => {
  const router = useRouter()
  const trackClick = useTrackClick()
  const { currentUserID, currentUser } = useCurrentUserContext()

  const submitRateAndSeeUrl = useCallback(
    async (url: string) => {
      trackClick('urlUpload')
      const { data } = await axios.request<{ url_id: string }>(MixMix.extract.submit({ url, label }))
      if (data.url_id && currentUserID) {
        await Promise.all(
          [
            IntoAPI.url.setInto({
              url_id: data.url_id,
            }),
            IntoAPI.url.markSeen({
              url_id: data.url_id,
            }),
            MixMix.commands.rateUrl(
              new RateUrl({
                urlId: Long.fromString(data.url_id),
                userId: currentUserID,
                ratingType: RateUrlType.RATE_URL_UP,
              })
            ),
            MixMix.commands.trackContentView(
              new TrackContentView({
                urlId: Long.fromString(data.url_id),
                userId: currentUserID,
                viewType: ContentViewType.CONTENT_VIEW,
              })
            ),
          ].map(axios.request)
        )
        await router.push(
          UrlHelper.urlIDPath({
            urlID: data.url_id,
            recId: 'into|unknown|user_submission',
            recBatchId: 'into|unknown|user_submission',
            likedBy: currentUser?.username,
          })
        )
      }
    },
    [currentUserID, router, currentUser?.username, trackClick, label]
  )

  return { submitRateAndSeeUrl }
}

export const AddUrlModal = () => {
  const dispatch = useAppDispatch()
  const { isAddUrlModalOpen: isVisible, url } = useAppSelector(state => state.addUrlModal)

  const inputRef = useRef<HTMLInputElement>(null)

  //#region Url meta
  const {
    data: urlMeta,
    isLoading: isLoadingUrlMeta,
    error: hasError,
  } = useRequest<ExtractMetaResponse, ApiError>(url ? MixMix.extract.getMeta({ url }) : null)
  //#endregion

  //#region User input changes
  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setUrl(convertStringToURL(event.target.value)))
      event.preventDefault()
    },
    [dispatch]
  )
  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, USER_ENTRY_DEBOUNCE), [changeHandler])
  //#endregion

  //#region Submit
  const { changePageContext } = useCommandContext()
  useIsomorphicLayoutEffect(() => {
    if (!isVisible) return
    changePageContext(new PageContext({ page: PageType.IN_APP_SHARE }))

    // Autofocus the input when isVisible becomes true.
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [isVisible, changePageContext])

  const clearAndClose = useCallback(() => {
    dispatch(setUrl(undefined))
    dispatch(setIsAddUrlModalOpen(false))
  }, [dispatch])

  const { submitRateAndSeeUrl } = useUrlSubmission()

  const onFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>, url: string) => {
      event.preventDefault()
      void toast.promise(submitRateAndSeeUrl(url), {
        loading: <>Submitting...</>,
        success: <>Added to Mix</>,
        error: e => errorToHelpfulMessage(e),
      })
      clearAndClose()
    },
    [clearAndClose, submitRateAndSeeUrl]
  )

  //#endregion

  return (
    <Modal isVisible={isVisible} onClose={clearAndClose}>
      <div className="relative z-10 w-80 rounded bg-menu p-6 text-menu sm:w-[30rem]">
        <div className="flex flex-col">
          <div className="self-center text-2xl font-semibold">Submit Link</div>
          <UrlMetaPreview url={url} urlMeta={urlMeta} isLoadingUrlMeta={isLoadingUrlMeta} />
        </div>
        <form
          className="flex flex-col space-y-2"
          onSubmit={event => (url ? onFormSubmit(event, url).catch(() => {}) : clearAndClose)}
        >
          <input
            ref={inputRef}
            type="url"
            defaultValue={url ?? undefined}
            onChange={debouncedChangeHandler}
            className={['block rounded bg-primary/[0.05]', hasError ? 'bg-red-300/50' : ''].join(' ')}
            placeholder="Type or paste link"
          />
          <input
            type="submit"
            disabled={!urlMeta}
            className="btn btn-secondary cursor-pointer self-center disabled:cursor-default disabled:bg-primary/20 disabled:text-menu/40 disabled:hover:scale-100 light:disabled:bg-contrast/10"
            value="Submit"
          />
        </form>
      </div>
    </Modal>
  )
}

export const UrlMetaPreview = ({
  url,
  urlMeta: propUrlMeta,
  isLoadingUrlMeta: propIsLoadingUrlMeta,
}: {
  url?: string | null
  urlMeta?: ExtractMetaResponse
  isLoadingUrlMeta?: boolean
}) => {
  const { data: fetchedUrlMeta, isLoading: fetchedIsLoadingUrlMeta } = useRequest<ExtractMetaResponse, ApiError>(
    !propUrlMeta && url ? MixMix.extract.getMeta({ url }) : null,
    { isOnline: () => !propUrlMeta && !propIsLoadingUrlMeta }
  )

  // Use either the props or the fetched data
  const urlMeta = propUrlMeta || fetchedUrlMeta
  const isLoadingUrlMeta = propIsLoadingUrlMeta ?? fetchedIsLoadingUrlMeta

  return (
    <div className="my-4 h-16">
      {urlMeta ? <UrlExtractMeta {...urlMeta} /> : <UrlExtractMetaPlaceholder isLoading={isLoadingUrlMeta} />}
    </div>
  )
}
