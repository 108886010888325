import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

import { NodeStreamPageContext } from '../../components/layouts/FullScreenFeedLayout'
import { globals } from '../../lib/messages/protobuf'
import { MediaType } from '../../models/MediaTypes'
import { AppState } from '../store/store'

import StreamType = globals.StreamType

export interface AppSlice {
  reqIP: string | null
  showGSignIn: boolean
  viewsLeftForNSFWPrompt: number | null
  pageSection: NodeStreamPageContext
  stream: StreamType | null
  mediaTypes: MediaType[]
  _metadata: { updatedFields: string[] }
}

const initialState: AppSlice = {
  reqIP: null,
  showGSignIn: false,
  viewsLeftForNSFWPrompt: null,
  pageSection: { value: 'home.loggedOut' },
  stream: StreamType.NODE_STREAM,
  mediaTypes: [],
  _metadata: { updatedFields: [] },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setReqIP: (state, action: PayloadAction<string>) => {
      state.reqIP = action.payload
    },
    setShowGSignIn: (state, action: PayloadAction<boolean>) => {
      state.showGSignIn = action.payload
      state._metadata.updatedFields.push('showGSignIn')
    },
    decrementViewsLeftForNSFWPrompt: state => {
      if (state.viewsLeftForNSFWPrompt !== null && state.viewsLeftForNSFWPrompt > 0) {
        state.viewsLeftForNSFWPrompt--
      } else state.viewsLeftForNSFWPrompt = null
      state._metadata.updatedFields.push('viewsLeftForNSFWPrompt')
    },
    setViewsLeftForNSFWPrompt: (state, action: PayloadAction<number>) => {
      state.viewsLeftForNSFWPrompt = action.payload
      state._metadata.updatedFields.push('viewsLeftForNSFWPrompt')
    },
    setPageSection: (state, action: PayloadAction<NodeStreamPageContext>) => {
      state.pageSection = action.payload
      state._metadata.updatedFields.push('pageSection')
    },
    setStream: (state, action: PayloadAction<StreamType | null>) => {
      state.stream = action.payload
      state._metadata.updatedFields.push('stream')
    },
    setMediaTypes: (state, action: PayloadAction<MediaType[]>) => {
      state.mediaTypes = action.payload
      state._metadata.updatedFields.push('mediaTypes')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.app

        const fieldsToCheck: (keyof AppSlice)[] = [
          'viewsLeftForNSFWPrompt',
          'showGSignIn',
          'pageSection',
          'stream',
          'mediaTypes',
        ]

        function updateField<K extends keyof AppSlice>(state: AppSlice, field: K, value: AppSlice[K]): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})
export const {
  setReqIP,
  setViewsLeftForNSFWPrompt,
  decrementViewsLeftForNSFWPrompt,
  setShowGSignIn,
  setPageSection,
  setStream,
  setMediaTypes,
} = appSlice.actions
export const selectShowGSignIn = (state: AppState) => state.app.showGSignIn
export const selectPageSection = (state: AppState) => state.app.pageSection
export const selectStream = (state: AppState) => state.app.stream
export const selectMediaTypes = (state: AppState) => state.app.mediaTypes

export default appSlice.reducer
