import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useTheme } from '../context/ThemeContext'
import { useIsDesktop } from '../hooks/useIsDesktop'
import { createDefaultAppVersion, createDefaultCommandContext } from '../lib/commands/CommandContextUtils'
import { commands, globals } from '../lib/messages/protobuf'
import { useGlobalContext } from './GlobalContexts'

import CommandContext = commands.CommandContext
import PageContext = globals.PageContext

interface CommandContextProps {
  commandContext: CommandContext
  changePageContext: (pageContext: PageContext) => void
}

const CommandContextContext = createContext<CommandContextProps | undefined>(undefined)

export const CommandContextProvider = ({ children }: { children: ReactNode }) => {
  const { browserInfo } = useGlobalContext()
  const { resolvedTheme } = useTheme()
  const isDesktop = useIsDesktop()
  const themePreference = resolvedTheme === 'dark' ? 'dark' : resolvedTheme === 'light' ? 'light' : undefined
  const [commandContext, setCommandContext] = useState(createDefaultCommandContext(browserInfo, themePreference))

  const changePageContext = useCallback(
    (pageContext: PageContext) => {
      if (JSON.stringify(pageContext) === JSON.stringify(commandContext.currentContext)) return

      setCommandContext(previousContext => {
        return new CommandContext({
          ...previousContext,
          session: {
            ...previousContext.session,
            device: {
              ...previousContext.session?.device,
              data: {
                ...previousContext.session?.device?.data,
                ...(themePreference && { theme_preference: themePreference }),
              },
            },
          },
          referrerContext: new PageContext({ ...previousContext.currentContext }),
          currentContext: pageContext,
        })
      })
    },
    [commandContext, themePreference]
  )

  useEffect(() => {
    setCommandContext(previousContext => {
      return new CommandContext({
        ...previousContext,
        session: {
          ...previousContext.session,
          appVersion: createDefaultAppVersion(isDesktop),
          device: {
            ...previousContext.session?.device,
            data: {
              ...previousContext.session?.device?.data,
              ...(themePreference && { theme_preference: themePreference }),
            },
          },
        },
      })
    })
  }, [themePreference, isDesktop, setCommandContext])

  const value = useMemo(
    () => ({
      commandContext,
      changePageContext,
    }),
    [commandContext, changePageContext]
  )

  return <CommandContextContext.Provider value={value}>{children}</CommandContextContext.Provider>
}

export const useCommandContext = () => {
  const result = useContext(CommandContextContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}
